import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { WelcomeFragment } from 'fragments/welcome/welcome.fragment';
import { LoginFragment } from 'fragments/login/login.fragment';
import { SignupFragment } from 'fragments/signup/signup.fragment';
import { SignupSuccessFragment } from 'fragments/signup-success/signup-success.fragment';
import { SignupCompletionFragment } from 'fragments/signup-completion/signup-completion.fragment';
import { SignupValidationFragment } from 'fragments/signup-validation/signup-validation.fragment';
import { useSignupValidationController } from 'fragments/signup-validation/signup-validation.controller';
import { SignupRedirectFragment } from 'fragments/signup-redirect/signup-redirect.fragment';
import { Error404Fragment } from 'fragments/error-404/error-404.fragment';
import { ResetPasswordFragment } from 'fragments/reset-password/reset-password.fragment';
import { SuccessFragment } from 'fragments/reset-password/success/success.fragment';
import { ErrorFragment } from 'fragments/reset-password/error/error.fragment';

export const AppRoutes: React.FC<any> = () => {
    return (
        <div>
            <Routes>
                <Route path={'/welcome'} element={<WelcomeFragment />} />
                <Route path={'/login'} element={<LoginFragment />} />
                <Route path={'/signup'} element={<SignupFragment />} />
                <Route path={'/signup/success'} element={<SignupSuccessFragment />} />
                <Route path={'/signup/completion'} element={<SignupCompletionFragment />} />
                <Route path={'/signup/validation'} element={<SignupValidationFragment />} />
                <Route path={'/recover'} element={<ResetPasswordFragment />} />
                <Route path={'/reset-password/success'} element={<SuccessFragment />} />
                <Route path={'/reset-password/error'} element={<ErrorFragment />} />
                <Route path={'/redirect'} element={<SignupRedirectFragment />} />
                <Route path={'/error/not-found'} element={<Error404Fragment />} />
                <Route path="*" element={<Navigate to="/welcome" replace />} />
            </Routes>
        </div>
    );
};
