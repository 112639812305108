import React from 'react';
import 'fragments/signup-validation/signup-validation.scss';
import { SignupValidationFragmentProps } from 'fragments/signup-validation/interfaces';
import { useSignupValidationController } from 'fragments/signup-validation/signup-validation.controller';
import { Button, Col, Form, Input, Row, Layout, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LeftOutlined } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const SignupValidationFragment: React.FC<SignupValidationFragmentProps> = (props) => {
    const { useController = useSignupValidationController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'signup'}>
            <Header className={'signup-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'signup-form'}
                        initialValues={{ remember: true }}
                        onFinish={controller.onValidateCodeSubmit}
                    >
                        <Row>
                            <Col span={2}>
                                <Title level={2}>
                                    <LeftOutlined onClick={controller.onBackPressed} />
                                </Title>
                            </Col>
                            <Col span={20}>
                                <Title level={2}> {translate({ key: 'signup-validation.title' })} </Title>
                            </Col>
                        </Row>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'signup-validation.subtitle' })}
                        </Text>
                        <Form.Item
                            label={<Text strong>{translate({ key: 'label.code' })}</Text>}
                            name="full_name"
                            rules={[{ required: true }]}
                        >
                            <Input
                                placeholder={translate({ key: 'form.placeholder-code' })}
                                disabled={controller.isLoading}
                                maxLength={6}
                                onChange={controller.onCodeChange}
                                value={controller.code}
                            />
                        </Form.Item>

                        <div className={'signup-forgot-container'}>
                            <Button
                                className={'button primary'}
                                type="primary"
                                block={true}
                                htmlType="submit"
                                loading={controller.isLoading}
                                disabled={controller.isCompleteButtonDisabled}
                            >
                                {translate({ key: 'signup-validation.continue-button-label' })}
                            </Button>
                            <Button
                                className={'button secondary'}
                                type="primary"
                                block={true}
                                // htmlType="submit"
                                loading={controller.isCodeLoading}
                                disabled={controller.isCodeLoading}
                                onClick={controller.onSendValidationCode}
                            >
                                ({controller.codeTimeout}) Reenviar
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
