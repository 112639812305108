import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WelcomeController } from 'fragments/welcome/interfaces';
import * as qs from 'qs';
import { useMainContext } from 'fragments/context/main/main.context';

export const useWelcomeController = (): WelcomeController => {
    const location = useLocation();
    const navigate = useNavigate();
    const mainContext = useMainContext();

    useEffect(() => {
        const url = qs.parse(location.search, { ignoreQueryPrefix: true }).url;
        if (url === undefined) {
            navigate('/error/not-found');
            return;
        }
        mainContext.setRedirectionUrl(url.toString());
    }, [location]);

    const onAccountButtonPressed = () => {
        navigate('/login');
    };
    const onNoAccountButtonPressed = () => {
        navigate('/signup');
    };

    return {
        onAccountButtonPressed,
        onNoAccountButtonPressed,
    };
};
