import React from 'react';
import 'fragments/reset-password/reset-password.scss';
import { ResetPasswordFragmentProps } from 'fragments/reset-password/interfaces';
import { useResetPasswordController } from 'fragments/reset-password/reset-password.controller';
import { Button, Col, Form, Input, Row, Layout, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LeftOutlined } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const ResetPasswordFragment: React.FC<ResetPasswordFragmentProps> = (props) => {
    const { useController = useResetPasswordController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'reset-password'}>
            <Header className={'reset-password-header'}>
                <img src={'./logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'reset-password-form'}
                        initialValues={{ remember: true }}
                        onFinish={controller.onResetPasswordSubmit}
                    >
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <Title level={2}> {translate({ key: 'reset-password.title' })} </Title>
                            </Col>
                        </Row>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'reset-password.subtitle' })}
                        </Text>
                        <Form.Item
                            label={<Text strong>{translate({ key: 'label.password' })}</Text>}
                            name={translate({ key: 'label.password' })}
                            rules={[{ required: true }]}
                        >
                            <Input.Password
                                placeholder={translate({ key: 'form.placeholder-enter-new-password' })}
                                disabled={controller.isLoading}
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                onChange={controller.onPasswordChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label={<Text strong>{translate({ key: 'label.confirmation-password' })}</Text>}
                            name={translate({ key: 'label.confirmation-password' })}
                            rules={[{ required: true }]}
                        >
                            <Input.Password
                                placeholder={translate({ key: 'form.placeholder-enter-confirm-new-password' })}
                                disabled={controller.isLoading}
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                onChange={controller.onPasswordConfirmationChange}
                            />
                        </Form.Item>

                        <div className={'reset-password-forgot-container'}>
                            <Button
                                className={'button primary'}
                                type="primary"
                                block={true}
                                htmlType="submit"
                                loading={controller.isLoading}
                                disabled={controller.isButtonDisabled}
                            >
                                {translate({ key: 'reset-password.button' })}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
