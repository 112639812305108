import React from 'react';
import 'fragments/signup-completion/signup-completion.scss';
import { SignupCompletionFragmentProps } from 'fragments/signup-completion/interfaces';
import { useSignupCompletionController } from 'fragments/signup-completion/signup-completion.controller';
import { Button, Col, Form, Input, Row, Layout, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LeftOutlined } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const SignupCompletionFragment: React.FC<SignupCompletionFragmentProps> = (props) => {
    const { useController = useSignupCompletionController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'signup'}>
            <Header className={'signup-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'signup-form'}
                        initialValues={{ remember: true }}
                        onFinish={controller.onCompleteSingupSubmit}
                    >
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <Title level={2}> {translate({ key: 'complete-signup.title' })} </Title>
                            </Col>
                        </Row>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'complete-signup.subtitle' })}
                        </Text>
                        <Form.Item
                            label={<Text strong>{translate({ key: 'label.full-name' })}</Text>}
                            name="full_name"
                            rules={[{ required: true }]}
                        >
                            <Input
                                placeholder={translate({ key: 'form.placeholder-full-name' })}
                                disabled={controller.isLoading}
                                onChange={controller.onFullNameChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label={<Text strong>{translate({ key: 'label.password' })}</Text>}
                            name="password"
                            rules={[{ required: true }]}
                        >
                            <Input.Password
                                placeholder={translate({ key: 'form.placeholder-enter-password' })}
                                disabled={controller.isLoading}
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                onChange={controller.onPasswordChange}
                            />
                        </Form.Item>

                        <div className={'signup-forgot-container'}>
                            <Button
                                className={'button primary'}
                                type="primary"
                                block={true}
                                htmlType="submit"
                                loading={controller.isLoading}
                                disabled={controller.isButtonDisabled}
                            >
                                {translate({ key: 'complete-signup.button' })}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
