import React from 'react';
import './success.scss';
import { SuccessFragmentProps } from './interfaces';
import { useSuccessController } from './success.controller';
import { Button, Col, Form, Input, Row, Layout, Typography, Progress, Modal } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const SuccessFragment: React.FC<SuccessFragmentProps> = (props) => {
    const { useController = useSuccessController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'success'}>
            <Header className={'success-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'success-form'}
                        initialValues={{ remember: true }}
                    >
                        <Title level={2}> {translate({ key: 'reset-password-success.title' })} </Title>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'reset-password-success.subtitle' })}
                        </Text>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
