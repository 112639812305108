import { useEffect, useState } from 'react';
import { SignupRedirectController } from 'fragments/signup-redirect/interfaces';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from 'fragments/context/main/main.context';

export const useSignupRedirectController = (): SignupRedirectController => {
    const [isLoading, setIsLoading] = useState(false);
    const mainContext = useMainContext();
    let timeoutID: NodeJS.Timeout;

    useEffect(() => {
        startRedirection();
    }, []);

    const onRedirect = () => {
        setIsLoading(true);
        redirect();
    };

    const startRedirection = () => {
        timeoutID = setTimeout(() => {
            redirect();
        }, 5000);
    };

    const redirect = () => {
        if (timeoutID !== undefined) {
            clearTimeout(timeoutID);
        }

        const url = mainContext.redirectionUrl;

        const finalUrl = new URL(url!);
        const redirectUrl =
            finalUrl.origin +
            finalUrl.pathname +
            (finalUrl.search != '' ? finalUrl.search + '&' : '?') +
            'token=' +
            mainContext.token;

        window.location.assign(redirectUrl);
    };

    return {
        isLoading,
        onRedirect,
    };
};
