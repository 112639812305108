import { useState } from 'react';
import { SignupCompletionController } from 'fragments/signup-completion/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { CompleteSignupRequest, CompleteSignupResponse } from 'services/auth/auth.service';
import { useAPIAuth } from 'services/auth/api-auth.service';
import { useMainContext } from 'fragments/context/main/main.context';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ErrorDto } from 'services/dtos/errors..dto';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useSignupCompletionController = (
    authService = useAPIAuth(),
    messenger = useMessenger(),
): SignupCompletionController => {
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [request, setRequest] = useState<CompleteSignupRequest>({ full_name: '', password: '' });
    const navigate = useNavigate();
    const passwordMinLength = 6;
    const passwordMaxLength = 12;
    const mainContext = useMainContext();
    const { translate } = useTranslator();

    const onCompleteSingupSubmit = () => {
        setIsLoading(true);
        const formError = validateForm(request);
        if (formError.length > 0) {
            messenger.showErrorMessage({ key: formError[0] });
            return;
        }

        const token = mainContext.completionToken;
        if (token === undefined) {
            // messenger.showErrorMessage("{ key: formError[0] }");
            return;
        }

        authService
            .completeSignup(token!, request)
            .then((response: CompleteSignupResponse) => {
                mainContext.setToken(response.access_token);
                mainContext.setCompletionToken(undefined);
                navigate('/signup/success');
            })
            .catch((e: ErrorDto) => {
                //TODO show error
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onBackPressed = () => {
        navigate(-1);
    };

    const onFullNameChange = (e: any) => {
        const newValue: CompleteSignupRequest = {
            ...request,
            full_name: e.target.value,
        };
        setRequest(newValue);
        enableButton(newValue);
    };

    const onPasswordChange = (e: any) => {
        const newValue: CompleteSignupRequest = {
            ...request,
            password: e.target.value,
        };
        setRequest(newValue);
        enableButton(newValue);
    };

    const enableButton = (value: CompleteSignupRequest) => {
        const status =
            value.full_name.length > 0 &&
            value.password.length >= passwordMinLength &&
            value.password.length <= passwordMaxLength;
        setIsButtonDisabled(!status);
    };

    const validateForm = (form: CompleteSignupRequest): string => {
        if (form.full_name === undefined) {
            return translate({ key: 'complete-signup.full-name-undefined' });
        }

        if (form.full_name.length < 1) {
            return translate({ key: 'complete-signup.full-name-min-length' });
        }

        if (form.password === undefined) {
            return translate({ key: 'complete-signup.password-undefined' });
        }

        if (form.password.length < passwordMinLength) {
            return translate({ key: 'complete-signup.password-min-length' });
        }

        return '';
    };

    return {
        isLoading,
        isButtonDisabled,
        onCompleteSingupSubmit,
        onBackPressed,
        onFullNameChange,
        onPasswordChange,
    };
};
