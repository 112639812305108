import {
    Auth,
    LoginRequest,
    LoginResponse,
    SignupRequest,
    SignupResponse,
    ValidateEmailRequest,
    PasswordRecoveryRequest,
    CompleteSignupRequest,
    CompleteSignupResponse,
    ValidateEmailResponse,
    ResetPasswordRequest,
} from 'services/auth/auth.service';
import { ErrorDto } from 'services/dtos/errors..dto';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useAPIAuth = (): Auth => {
    const [session] = useLocalSession();

    const login = async (input: LoginRequest): Promise<LoginResponse> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/login', input, {});
            return response.data as LoginResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const signup = async (input: SignupRequest): Promise<SignupResponse> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/signup', input, {});
            return response.data as SignupResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const validateEmail = async (token: string, input: ValidateEmailRequest): Promise<ValidateEmailResponse> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/validate', input, {
                headers: { Authorization: 'Bearer ' + token },
            });
            return response.data as ValidateEmailResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const completeSignup = async (token: string, input: CompleteSignupRequest): Promise<CompleteSignupResponse> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/complete', input, {
                headers: { Authorization: 'Bearer ' + token },
            });
            return response.data as CompleteSignupResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const resendValidationEmail = async (token: string): Promise<unknown> => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/resend', {
                headers: { Authorization: 'Bearer ' + token },
            });
            return response.data as Record<string, unknown>;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const passwordRecovery = async (input: PasswordRecoveryRequest): Promise<unknown> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/recover', input, {});
            return response.data as Record<string, unknown>;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const resetPassword = async (input: ResetPasswordRequest): Promise<unknown> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/reset-password', input, {});
            return response.data as Record<string, unknown>;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    return {
        login,
        signup,
        validateEmail,
        completeSignup,
        resendValidationEmail,
        passwordRecovery,
        resetPassword,
    };
};
