import { useEffect, useState } from 'react';
import { SignupValidationController } from 'fragments/signup-validation/interfaces';
import { useNavigate } from 'react-router-dom';
import { useAPIAuth } from 'services/auth/api-auth.service';
import { ValidateEmailRequest } from 'services/auth/auth.service';
import { ErrorDto } from 'services/dtos/errors..dto';
import { useMainContext } from 'fragments/context/main/main.context';

export const useSignupValidationController = (apiAuth = useAPIAuth()): SignupValidationController => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCodeLoading, setIsCodeLoading] = useState(true);
    const [isCompleteButtonDisabled, setIsCompleteButtonDisabled] = useState(false);
    const defaultCodeTimeout = 5;
    const codeLength = 6;
    const [codeTimeout, setCodeTimeout] = useState(defaultCodeTimeout);
    const [code, setCode] = useState('');
    const mainContext = useMainContext();

    const navigate = useNavigate();

    useEffect(() => {
        startCodeTimeout();
    }, []);

    const onValidateCodeSubmit = () => {
        setIsLoading(true);
        // navigate('/signup/completion');
        if (code.length != codeLength) {
            //TODO show error
            setIsLoading(false);
            return;
        }

        const value: number = +code;
        if (isNaN(value)) {
            //TODO show error
            setIsLoading(false);
            return;
        }

        const finalCode: string = fillValidationCode(code.toString());

        const request: ValidateEmailRequest = {
            code: finalCode,
        };

        const token = mainContext.validationToken;
        if (token === null) {
            //TODO handle error
            return;
        }
        apiAuth
            .validateEmail(token!, request)
            .then((response) => {
                mainContext.setValidationToken(undefined);
                mainContext.setCompletionToken(response.access_token);
                navigate('/signup/completion');
            })
            .catch((e: ErrorDto) => {
                //TODO handle error
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onBackPressed = () => {
        navigate(-1);
    };

    const onSendValidationCode = () => {
        setIsCodeLoading(true);
        startCodeTimeout();
    };

    const onCodeChange = (e: any) => {
        setCode(e.target.value);
    };

    const startCodeTimeout = () => {
        let newTimeout = codeTimeout;
        const interval = setInterval(() => {
            newTimeout--;
            if (newTimeout <= 0) {
                setIsCodeLoading(false);
                cancelInterval();
            }
            setCodeTimeout(newTimeout);
        }, 1000);

        const cancelInterval = () => {
            clearInterval(interval);
        };
    };

    const fillValidationCode = (code: string): string => {
        for (let i = code.length; i < codeLength; i++) {
            code = '0' + code;
        }
        return code;
    };

    return {
        code,
        isLoading,
        isCodeLoading,
        isCompleteButtonDisabled,
        codeTimeout,
        onValidateCodeSubmit,
        onBackPressed,
        onSendValidationCode,
        onCodeChange,
    };
};
