import React, { useEffect, useState } from 'react';
import { SESSION_STORAGE_SESSION_KEY } from 'auth/helpers/session.hooks';
import { SessionContext } from 'auth/helpers/session.context';
import { Session } from 'auth/helpers/session';
import AuthRoute from 'auth/components/auth-route.component';
import { MainFragment } from 'fragments/main/main.fragment';
// import { LandingFragment } from 'fragments/landing/landing.fragment';

type Props = {
    children?: React.ReactNode;
};

const SessionProvider: React.FC<Props> = (props) => {
    const [session, setSession] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadSessionFromLocalStorage().finally();
    }, []);

    const loadSessionFromLocalStorage = async () => {
        const storedSession = await window.sessionStorage.getItem(SESSION_STORAGE_SESSION_KEY);
        if (storedSession) {
            const sessionModel = JSON.parse(storedSession) as Session;
            setSession(sessionModel);
        }
        setIsLoading(false);
    };

    return (
        <SessionContext.Provider value={{ isLoading, session, setSession }}>
            {/*Comment following line for using auth*/}
            {/*{props.children}*/}

            {/*Uncomment following line for using auth*/}
            {/* <AuthRoute>{props.children}</AuthRoute> */}
            {/* <LandingFragment>{{}}</LandingFragment> */}
            <MainFragment></MainFragment>
        </SessionContext.Provider>
    );
};

export default SessionProvider;
