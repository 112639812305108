import React from 'react';
import 'fragments/welcome/welcome.scss';
import { WelcomeFragmentProps } from 'fragments/welcome/interfaces';
import { useWelcomeController } from 'fragments/welcome/welcome.controller';
import { Row, Col, Typography, Button } from 'antd';
const { Text } = Typography;

export const WelcomeFragment: React.FC<WelcomeFragmentProps> = (props) => {
    const { useController = useWelcomeController } = props;
    const controller = useController();

    return (
        <div className={'welcome'}>
            <Row align={'middle'} justify={'space-around'}>
                <Col span={16}>
                    <Button
                        block
                        size={'large'}
                        onClick={controller.onAccountButtonPressed}
                        style={{
                            whiteSpace: 'normal',
                            height: 'auto',
                            marginBottom: '10px',
                            paddingBottom: '20px',
                            paddingTop: '20px',
                        }}
                    >
                        Ya tengo cuenta en accefy.
                    </Button>
                    <Button
                        block
                        size={'large'}
                        onClick={controller.onNoAccountButtonPressed}
                        style={{
                            whiteSpace: 'normal',
                            height: 'auto',
                            marginBottom: '10px',
                            paddingBottom: '20px',
                            paddingTop: '20px',
                        }}
                    >
                        <Text>Aun no tengo cuenta en accefy, quiero crear una.</Text>
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
