import React from 'react';
import './error.scss';
import { ErrorFragmentProps } from './interfaces';
import { useErrorController } from './error.controller';
import { Button, Col, Form, Input, Row, Layout, Typography, Progress, Modal } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const ErrorFragment: React.FC<ErrorFragmentProps> = (props) => {
    const { useController = useErrorController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'error'}>
            <Header className={'error-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form name="basic" layout={'vertical'} className={'error-form'} initialValues={{ remember: true }}>
                        <Title level={2}> {translate({ key: 'reset-password-error.title' })} </Title>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'reset-password-error.subtitle' })}
                        </Text>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
