import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordController } from 'fragments/reset-password/interfaces';
import { useAPIAuth } from 'services/auth/api-auth.service';
import { ErrorDto } from 'services/dtos/errors..dto';
import { ResetPasswordRequest } from 'services/auth/auth.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import * as qs from 'qs';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { TranslatableString } from 'tools/view-hooks/messenger-hook';

export const useResetPasswordController = (
    apiAuth = useAPIAuth(),
    messenger = useMessenger(),
): ResetPasswordController => {
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const passwordMinLength = 6;
    const passwordMaxLength = 100;
    const recoveryTokenLength = 22;
    const [request, setRequest] = useState<ResetPasswordRequest>({
        password: '',
        password_confirmation: '',
        recovery_token: '',
    });

    const navigate = useNavigate();
    const { translate } = useTranslator();
    useEffect(() => {
        const rawRecoveryToken: string = window.location.href.split('#', 2)[1];
        if (rawRecoveryToken === undefined) {
            navigate('/error/not-found');
            return;
        }

        const tmp_recovery_token = qs.parse(rawRecoveryToken, { ignoreQueryPrefix: true }).recovery_token;
        if (tmp_recovery_token === undefined) {
            navigate('/error/not-found');
            return;
        }
        const newValue: ResetPasswordRequest = {
            ...request,
            recovery_token: tmp_recovery_token.toString(),
        };
        setRequest(newValue);
    }, [location]);

    const onResetPasswordSubmit = () => {
        setIsLoading(true);
        const formError = validateForm(request);
        if (formError.key) {
            messenger.showErrorMessage(formError);
            setIsLoading(false);
            return;
        }

        if (request.password !== request.password_confirmation) {
            messenger.showErrorMessage({ key: 'reset-password.password-and-password-confirmation-unmatching' });
            setIsLoading(false);
            return;
        }

        apiAuth
            .resetPassword(request)
            .then(() => {
                navigate('/reset-password/success');
            })
            .catch((e: ErrorDto) => {
                switch (e.response.data.msg) {
                    case 'token':
                        navigate('/reset-password/error');
                        break;
                    case 'password':
                        messenger.showErrorMessage({ key: 'reset-password.error.password' });
                        break;
                    default:
                        messenger.showErrorMessage({ key: 'error.unavailable' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onBackPressed = () => {
        navigate(-1);
    };

    const onPasswordChange = (e: any) => {
        const newValue: ResetPasswordRequest = {
            ...request,
            password: e.target.value,
        };
        setRequest(newValue);
        enableButton(newValue);
    };

    const onPasswordConfirmationChange = (e: any) => {
        const newValue: ResetPasswordRequest = {
            ...request,
            password_confirmation: e.target.value,
        };
        setRequest(newValue);
        enableButton(newValue);
    };

    const enableButton = (value: ResetPasswordRequest) => {
        const status =
            value.password.length >= passwordMinLength &&
            value.password.length <= passwordMaxLength &&
            value.password_confirmation.length >= passwordMinLength &&
            value.password_confirmation.length <= passwordMaxLength &&
            value.password.length == value.password_confirmation.length &&
            value.recovery_token.length == recoveryTokenLength;
        setIsButtonDisabled(!status);
    };

    const validateForm = (form: ResetPasswordRequest): TranslatableString => {
        if (form.password === undefined) {
            return { key: 'reset-password.password-undefined' };
        }

        if (form.password.length < passwordMinLength) {
            return { key: 'reset-password.password-min-length' };
        }

        if (form.password.length > passwordMaxLength) {
            return { key: 'reset-password.password-max-length' };
        }

        if (form.password_confirmation === undefined) {
            return { key: 'reset-password.password-confirmation-undefined' };
        }

        if (form.password_confirmation.length < passwordMinLength) {
            return { key: 'reset-password.password-confirmation-min-length' };
        }

        if (form.password_confirmation.length > passwordMaxLength) {
            return { key: 'reset-password.password-confirmation-max-length' };
        }

        if (form.password.length != form.password_confirmation.length) {
            return { key: 'reset-password.password-and-password-confirmation-unmatching-length' };
        }

        return { key: '' };
    };

    return {
        isLoading,
        isButtonDisabled,
        onResetPasswordSubmit,
        onBackPressed,
        onPasswordChange,
        onPasswordConfirmationChange,
    };
};
