import React from 'react';
import 'fragments/error-404/error-404.scss';
import { Error404FragmentProps } from 'fragments/error-404/interfaces';
import { useError404Controller } from 'fragments/error-404/error-404.controller';
import { Button, Col, Form, Input, Row, Layout, Typography, Progress, Modal } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const Error404Fragment: React.FC<Error404FragmentProps> = (props) => {
    const { useController = useError404Controller } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'signup-success'}>
            <Header className={'signup-success-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'signup-success-form'}
                        initialValues={{ remember: true }}
                    >
                        <Title level={2}> {translate({ key: 'error-not-found.title' })} </Title>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'error-not-found.subtitle' })}
                        </Text>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
