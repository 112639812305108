import { useEffect, useState } from 'react';
import { SignupSuccessController } from 'fragments/signup-success/interfaces';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from 'fragments/context/main/main.context';

export const useSignupSuccessController = (): /* <--Dependency Injections  like services hooks */
SignupSuccessController => {
    const defaultTimeout = 5;
    const [timeout, setTimeout] = useState(defaultTimeout);

    const navigate = useNavigate();
    const mainContext = useMainContext();

    useEffect(() => {
        if (mainContext.redirectionUrl == undefined) {
            return;
        }
        const { token } = mainContext;
        window.location.assign(mainContext.redirectionUrl! + '&token=' + token);
    }, [mainContext]);

    const onGoToLogin = () => {
        navigate('/login');
    };

    return {
        timeout,
        onGoToLogin,
    };
};
