import React from 'react';
import 'fragments/signup-success/signup-success.scss';
import { SignupSuccessFragmentProps } from 'fragments/signup-success/interfaces';
import { useSignupSuccessController } from 'fragments/signup-success/signup-success.controller';
import { Button, Col, Form, Input, Row, Layout, Typography, Progress, Modal } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const SignupSuccessFragment: React.FC<SignupSuccessFragmentProps> = (props) => {
    const { useController = useSignupSuccessController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'signup-success'}>
            <Header className={'signup-success-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'signup-success-form'}
                        initialValues={{ remember: true }}
                    >
                        <Title level={2}> {translate({ key: 'signup-success.title' })} </Title>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'signup-success.subtitle' })}
                        </Text>

                        <Button
                            className={'button secondary'}
                            type="primary"
                            block={true}
                            // htmlType="submit"
                            onClick={controller.onGoToLogin}
                        >
                            {translate({ key: 'signup-success.button' })}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
