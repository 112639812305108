import { LoginInput, SendResetPasswordMailInput } from 'auth/services/auth/auth.service';
import { useState } from 'react';
import { Form } from 'antd';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPIAuth } from 'services/auth/api-auth.service';
import { LoginController } from './interfaces';
import { useMainContext } from 'fragments/context/main/main.context';
import { LoginRequest, PasswordRecoveryRequest } from 'services/auth/auth.service';

export const useLoginController = (apiAuth = useAPIAuth(), messenger = useMessenger()): LoginController => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRecoverPassLoading, setIsRecoverPassLoading] = useState(false);
    const [isRecoverPassVisible, setIsRecoverPassVisible] = useState(false);
    const [password, setPassword] = useState<string>('');
    const mainContext = useMainContext();

    const [recoverPassForm] = Form.useForm();
    const navigate = useNavigate();

    const onLoginSubmit = (formInputs: unknown) => {
        setIsLoading(true);
        const input = formInputs as LoginRequest;
        input.password = password;
        input.email = input.email.toLowerCase();
        apiAuth
            .login(input)
            .then((response) => {
                mainContext.setToken(response.access_token);
                navigate('/redirect');
            })
            .catch((errorCode: string) => {
                //TODO check errors
                if (errorCode == 'invalid_grant%!(EXTRA string=Invalid credentials)') {
                    messenger.showErrorMessage({ key: 'auth.login-error-invalid-credentials' });
                } else {
                    messenger.showErrorMessage({ key: 'auth.login-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onBackPressed = () => {
        navigate(-1);
    };

    const onRecoverPassSubmit = (formInputs: unknown) => {
        setIsRecoverPassLoading(true);
        const input = formInputs as PasswordRecoveryRequest;
        apiAuth
            .passwordRecovery(input)
            .then(() => {
                recoverPassForm.resetFields();
                messenger.showSuccessMessage({ key: 'auth.forgot-pass-success' });
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'auth.forgot-pass-error' });
            })
            .finally(() => {
                recoverPassForm.resetFields();
                setIsRecoverPassVisible(false);
                setIsRecoverPassLoading(false);
            });
    };

    const onCancelRecoverPass = () => {
        recoverPassForm.resetFields();
        setIsRecoverPassVisible(false);
    };

    const onForgotPassPressed = () => {
        setIsRecoverPassVisible(true);
    };

    const onPasswordInputChange = (e: any) => {
        setPassword(e.target.value);
    };

    return {
        recoverPassForm,
        isLoading,
        password,
        isRecoverPassLoading,
        isRecoverPassVisible,
        onLoginSubmit,
        onRecoverPassSubmit,
        onCancelRecoverPass,
        onForgotPassPressed,
        onPasswordInputChange,
        onBackPressed,
    };
};
