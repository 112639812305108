import React, { useContext } from 'react';
import { Session } from 'auth/helpers/session';

export interface MainContextInterface {
    redirectionUrl?: string;
    token?: string;
    validationToken?: string;
    completionToken?: string;
    setRedirectionUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
    setValidationToken: React.Dispatch<React.SetStateAction<string | undefined>>;
    setCompletionToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const MainContext = React.createContext<MainContextInterface>({
    redirectionUrl: '',
    token: '',
    validationToken: '',
    completionToken: '',

    setRedirectionUrl: () => {
        /* Nothing to do here */
    },
    setToken: () => {
        /* Nothing to do here */
    },
    setValidationToken: () => {
        /* Nothing to do here */
    },
    setCompletionToken: () => {
        /* Nothing to do here */
    },
});

export const useMainContext = (): MainContextInterface => {
    const store = useContext(MainContext);
    return store;
};
