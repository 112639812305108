import React, { useState } from 'react';
import { MainContext } from 'fragments/context/main/main.context';

type Props = {
    children?: React.ReactNode;
};

const MainProvider: React.FC<Props> = (props) => {
    const [redirectionUrl, setRedirectionUrl] = useState<string>();
    const [token, setToken] = useState<string>();
    const [validationToken, setValidationToken] = useState<string>();
    const [completionToken, setCompletionToken] = useState<string>();

    return (
        <MainContext.Provider
            value={{
                redirectionUrl,
                setRedirectionUrl,
                token,
                setToken,
                validationToken,
                setValidationToken,
                completionToken,
                setCompletionToken,
            }}
        >
            {props.children}
        </MainContext.Provider>
    );
};

export default MainProvider;
