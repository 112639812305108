import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignupController } from 'fragments/signup/interfaces';
import { useAPIAuth } from 'services/auth/api-auth.service';
import { SignupRequest, SignupResponse } from 'services/auth/auth.service';
import { useMainContext } from 'fragments/context/main/main.context';
import { ErrorDto } from 'services/dtos/errors..dto';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useSignupController = (apiAuth = useAPIAuth(), messenger = useMessenger()): SignupController => {
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [email, setEmail] = useState('');
    const emailMinimalLength = 3;
    const mainContext = useMainContext();

    const navigate = useNavigate();

    const onSignupSubmit = (formInputs: unknown) => {
        setIsLoading(true);
        const regexp = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
        if (!regexp.test(email)) {
            messenger.showErrorMessage({ key: 'signup.error.email-format' });
            setIsLoading(false);
            return;
        }

        const form: SignupRequest = {
            email: email,
        };

        apiAuth
            .signup(form)
            .then((response: SignupResponse) => {
                mainContext.setValidationToken(response.access_token);
                navigate('/signup/validation');
            })
            .catch((e: ErrorDto) => {
                if (e.response.status == 409) {
                    messenger.showErrorMessage({ key: 'signup.error.email-duplicated' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onEmailChange = (e: any) => {
        const { value } = e.target;
        setEmail(value);
        setIsButtonDisabled(value.length < emailMinimalLength);
    };

    const onBackPressed = () => {
        navigate(-1);
    };

    return {
        isLoading,
        isButtonDisabled,
        onSignupSubmit,
        onBackPressed,
        onEmailChange,
    };
};
